const formAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    left: "-1.5rem",
  },
  animate: {
    opacity: 1,
    left: 0,
  },
  transition: {
    duration: 0.75,
    delay: 0.6,
  },
};

const textAnimation = {
  style: {
    position: "relative",
  },
  initial: {
    opacity: 0,
    top: "0.4rem",
  },
  animate: {
    opacity: 1,
    top: 0,
  },
  transition: {
    duration: 0.65,
    delay: 0.85,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  form: formAnimation,
  text: textAnimation,
};
