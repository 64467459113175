const sendEmailUseCase = (sendEmailRepo) => {
  const sendEmail = async ({ name, email, message }) => {
    return sendEmailRepo.sendEmail({ name, email, message });
  };

  return {
    sendEmail,
  };
};

export default sendEmailUseCase;
