import React from "react";
import { Box } from "@chakra-ui/react";

function ScrollBreakpoint({ id, space, children }) {
  return (
    <Box position="relative">
      <section
        id={id}
        style={{
          position: "absolute",
          top: `-${space}px`,
          width: "100%",
        }}
      />

      {children}
    </Box>
  );
}

ScrollBreakpoint.defaultProps = {
  space: 40,
};

export default ScrollBreakpoint;
