import axios from "axios";

const domain = process.env.GATSBY_EMAIL_LAMBDA_DOMAIN;
const endpoint = process.env.GATSBY_EMAIL_LAMBDA_ENDPOINT;

const axiosInstance = axios.create({
  baseURL: domain,
});

const SendEmailService = () => {
  const sendEmail = async ({ name, email, message }) => {
    const response = await axiosInstance.post(endpoint, {
      name: name,
      email: email,
      message: message,
    });

    return response;
  };

  return {
    sendEmail,
  };
};

export default SendEmailService;
