import React, { useCallback } from "react";
import { useForm } from "react-hook-form";
import { Box, Flex, Button, Text, useToast } from "@chakra-ui/react";
import { useTranslation } from "../../../core/hooks/useTranslation";
import { useSendEmail } from "../../../core/hooks/useSendEmail";
import FormControl from "../../../components/FormControl";

function ContactForm() {
  const { t } = useTranslation();
  const toast = useToast();
  const { isLoading, sendEmail } = useSendEmail();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onFormSubmit = useCallback(async (data) => {
    await sendEmail(
      {
        name: data.name,
        email: data.email.toLowerCase(),
        message: data.message,
      },
      {
        onSuccess: (response) => {
          toast({
            title: t("contact@toast@success"),
            status: "success",
            isCloseable: true,
          });

          reset();
        },
        onError: (e) => {
          console.log(e, "  send email error");
          toast({
            title:
              process.env.NODE_ENV === "production"
                ? t("contact@toast@failure")
                : e.message,
            status: "error",
            isCloseable: true,
          });
        },
      }
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Box marginBottom="viewHomeContactInputRow">
        <Flex
          flexDirection={{ base: "column", lg: "row" }}
          justifyContent="space-between"
        >
          <Box
            width={{ base: "100%", lg: "viewHomeContactInputW" }}
            mb={{ base: "viewHomeContactInputRow", lg: 0 }}
          >
            <FormControl
              label={t("contact@name")}
              {...register("name", { required: true })}
            />
            {!!errors.name && (
              <Text
                as="span"
                color="orangered"
                fontSize="sm"
                fontWeight="semibold"
              >
                {t("contact@name-error")}
              </Text>
            )}
          </Box>
          <Box width={{ base: "100%", lg: "viewHomeContactInputW" }}>
            <FormControl
              disabled={isLoading}
              label={t("contact@email")}
              {...register("email", {
                required: true,
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-z]{2,4}$/,
              })}
            />
            {!!errors.email && (
              <Text
                as="span"
                color="orangered"
                fontSize="sm"
                fontWeight="semibold"
              >
                {t("contact@email-error")}
              </Text>
            )}
          </Box>
        </Flex>
      </Box>

      <Box marginBottom="viewHomeContactInputRow">
        <FormControl
          disabled={isLoading}
          multiline={true}
          label={t("contact@message")}
          {...register("message", { required: true })}
        />
        {!!errors.message && (
          <Text as="span" color="orangered" fontSize="sm" fontWeight="semibold">
            {t("contact@message-error")}
          </Text>
        )}
      </Box>

      <Box marginBottom="viewHomeContactInputRow">
        <Button
          type="submit"
          backgroundColor="blue.600"
          color="white"
          fontSize="sm"
          height="3xl"
          minWidth="5xl"
          borderRadius={0}
          disabled={isLoading}
        >
          {t("contact@send")}
        </Button>
      </Box>
    </form>
  );
}

export default ContactForm;
