const sendEmailRepo = (SendEmailService) => {
  const sendEmail = async ({ name, email, message }) => {
    return SendEmailService.sendEmail({ name, email, message });
  };

  return {
    sendEmail,
  };
};

export default sendEmailRepo;
