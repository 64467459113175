import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import ViewportAnimation from "../../../components/ViewportAnimation";
import ScrollBreakpoint from "../../../components/ScrollBreakpoint";
import PageContainer from "../../../components/PageContainer";
import ContactForm from "./ContactForm";
import animation from "./_animation";

function Contact({ children }) {
  return (
    <ScrollBreakpoint id="contact-us">
      <PageContainer>
        <Box
          minHeight="viewHomeContact"
          paddingTop={{ base: "3xl", sm: "viewHomeContactTop" }}
        >
          <Flex
            flexDirection={{ base: "column", lg: "row" }}
            justifyContent="space-between"
          >
            <Box
              maxWidth={{ base: "100%", lg: "viewHomeContactCol1" }}
              width={{ base: "100%", lg: "50%" }}
            >
              <ViewportAnimation {...animation.text}>
                {children}
              </ViewportAnimation>
            </Box>

            <Box
              maxWidth={{ base: "100%", lg: "viewHomeContactCol2" }}
              width={{ base: "100%", lg: "50%" }}
              marginTop={{ base: "4xl", lg: 0 }}
            >
              <ViewportAnimation {...animation.form}>
                <ContactForm />
              </ViewportAnimation>
            </Box>
          </Flex>
        </Box>
      </PageContainer>
    </ScrollBreakpoint>
  );
}

export default Contact;
