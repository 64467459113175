import { useMutation } from "react-query";
import { sendEmailUseCase } from "../factories";

export const useSendEmail = () => {
  const {
    mutate: sendEmail,
    isLoading,
    data,
    error,
  } = useMutation(sendEmailUseCase.sendEmail);

  return {
    sendEmail,
    isLoading,
    data,
    error,
  };
};
